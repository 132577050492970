@import "~react-big-calendar/lib/css/react-big-calendar.css";
#tasks-container {
  clear: both;
  height: 100%;
  min-height: 150px;
  padding: 0;
  padding-left: 5px;
  @media screen and (max-width: 925px) {
    padding-bottom: 20px;
  }
  @media screen and (min-width: 992px) {
    height: unset;
  }
}

.calendarDraggable {
  line-height: 1.2;
  .fc-event-past,
  .fc-event-today,
  .fc-event-future {
    background-image: $orange-gradient;
    border: none;
  }
  .fc-scrollgrid-shrink {
    font-weight: bold;
    @include themify($themes) {
      color: themed("calendarText");
    }
  }
  .fc-event-main-frame {
    font-size: 120%;
  }

  @include themify($themes) {
    .fc-col-header {
      width: 100% !important;
      .fc-col-header-cell {
        justify-content: flex-start;
        border-bottom: 2px solid themed("calendarHover");

        &:first-child {
          padding-left: 10px;
        }

        & + .fc-col-header-cell {
          text-align: center;
        }
      }

      .fc-col-header-cell-cushion {
        color: themed("calendarText");
        //font-weight: normal;
      }
    }
  }

  .fc-col-header-cell,
  .fc-scrollgrid {
    border: none;
    tbody {
      height: 100%;

      & tr {
        height: 17px;
      }
    }
    .fc-scrollgrid-section-body:nth-child(1) {
      display: none;
    }
    .fc-scrollgrid-section:nth-child(2) {
      display: none;
    }
  }

  .fc-daygrid-day-top {
    flex-direction: row !important;
    text-align: left;
  }

  .fc-toolbar {
    &:hover {
      background-color: transparent !important;
    }

    margin-bottom: 3.5em;

    & h2 {
      width: 200px;
      text-align: center;
    }
    .fc-button-group > .fc-button:not(:first-child) {
      margin-left: 10px;
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
    }
    .fc-button-group > .fc-button:not(:last-child) {
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
    }

    .fc-toolbar-title {
      text-transform: capitalize;
      color: $color-gray;
      width: fit-content;
    }

    button {
      height: 40px;
      font-weight: bold;
      justify-content: center;
      align-items: center;
      text-align: center;
      display: inline-flex;
      border-radius: 10px;
      font-size: 12px;
      transition: all 0.3s;
      padding: 8px 14px;
      cursor: pointer;
      color: black;
      background-color: transparent;
      @include themify($themes) {
        border: 1px solid themed("colorButtonBorder");
      }

      &:focus,
      &:active,
      &:focus:active {
        outline: none;
        box-shadow: none !important;
        background: transparent;
        @include themify($themes) {
          color: themed("colorButtonBorder");
        }
      }

      &:hover {
        @include themify($themes) {
          background-color: themed("colorButtonHover");
        }
      }
    }
    & .fc-timeGridWeek-button,
    .fc-timeGridDay-button {
      @extend .btn-gradient;
      background-image: white;
      font-size: 15px;
      text-transform: none;
      font-weight: normal;
      background-image: none;
      @include themify($themes) {
        color: themed("colorDarkText");
      }
      &.fc-button-active {
        background-image: $orange-gradient;
        border: none;
      }
    }

    & .fc-prev-button,
    .fc-next-button {
      @include themify($themes) {
        color: themed("colorButtonBorder");

        &:active,
        &:hover {
          color: themed("colorButtonBorder");
        }
      }
    }

    & .fc-scrollgrid-sync-inner {
      text-align: left;
    }

    & .fc-button-active {
      box-shadow: none;
      pointer-events: none;
      border: 1px solid black;
      background-color: transparent !important;
      color: white !important;
    }

    .fc-toolbar-chunk:nth-child(2) {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      & * {
        margin: 0;
      }

      & * + * {
        margin-left: 30px;
      }
    }
    .fc-toolbar-chunk:nth-child(1) {
      & button, & button:active, & button:focus, & button:active:focus {
        @extend .btn-gradient;
        text-transform: none;
        font-size: 15px !important;
        line-height: 15px;
        font-weight: normal;
      }
    }
  }
  .fc-event-time {
    font-weight: bold;
  }
}

.calendar,
.tasks-list-container {
  height: 600px;
  max-width: 100%;
  min-width: 500px;
  margin-bottom: 25px;
  .fc-media-screen,
  .fc-view-harness,
  .fc-daygrid,
  .fc-scrollgrid,
  tbody .fc-scroller-harness,
  tbody .fc-scroller,
  .fc-daygrid-body,
  #tasks-container {
    width: 100%;
  }
  #calendar-inner_container {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 15px;
  }

  .fc-view-harness {
    width: 40%;
  }
  .fc-scrollgrid {
    display: flex;
    flex-direction: column;

    thead {
      display: flex;

      & .fc-scrollgrid-section {
        height: initial;
      }
    }
    tbody {
      display: flex;
      @-moz-document url-prefix() {
        display: block;
      }
      height: 100%;
      & td {
        height: 100%;
      }

      & .fc-scrollgrid-section {
        height: 100%;
        width: 100%;
        & > td {
          height: 100%;
          display: flex;
        }

        & .fc-daygrid-body {
          width: 100% !important;

          & table {
            width: 100% !important;
          }
        }

        & .fc-scrollgrid-sync-table {
          display: flex;
          width: 100%;

          & tbody {
            width: 100%;
          }
        }
      }
    }
  }

  .fc-scrollgrid-sync-table,
  .fc-col-header {
    height: 100% !important;
    width: 100%;
    tr,
    th {
      display: flex;
      flex-direction: row;
      flex: 1;
      align-items: center;
      justify-content: center;
      @include themify($themes) {
        border-bottom: 2px solid themed("calendarHover");
      }
    }
    td {
      display: flex;
      flex: 1;
    }

    td + td {
      margin-left: 15px;
    }

    tbody {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }

  .fc-view-harness {
    height: min-content;
    border: none;
    @include themify($themes) {
      background-color: themed("calendarBackground");
    }
    border-radius: 10px;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
    //width: 60%;
    padding: 10px 20px;
    & * {
      border: none;
    }
  }

  .fc-icon-fa {
    font-family: "FontAwesome";
  }

  .fc-toolbar-title {
    text-transform: capitalize;
    color: $color-gray;
    font-size: 150%;
  }
  .fc-daygrid-day-number {
    @include themify($themes) {
      color: themed("calendarText");
    }
    font-weight: bold;
  }

  .fc-daygrid-day-top {
    flex-direction: row !important;
    text-align: left;
  }

  .fc-toolbar {
    &:hover {
      background-color: transparent !important;
    }

    margin-bottom: 3.5em;

    & h2 {
      width: 200px;
      text-align: center;
    }

    button {
      height: 40px;
      font-weight: bold;
      justify-content: center;
      align-items: center;
      text-align: center;
      display: inline-flex;
      border-radius: 10px;
      font-size: 12px;
      transition: all 0.3s;
      padding: 8px 14px;
      cursor: pointer;
      background-color: transparent;
      @include themify($themes) {
        border: 1px solid themed("colorButtonBorder");
      }

      &:focus,
      &:active,
      &:focus:active {
        outline: none;
        box-shadow: none !important;
        background: transparent;
      }

      &:hover {
        @include themify($themes) {
          background-color: themed("colorButtonHover");
        }
      }
    }

    & .fc-prev-button,
    .fc-next-button {
      @include themify($themes) {
        color: themed("colorButtonBorder");

        &:active,
        &:hover {
          color: themed("colorButtonBorder");
        }
      }
    }

    & .fc-scrollgrid-sync-inner {
      text-align: left;
    }

    & .fc-button-active {
      box-shadow: none;
      pointer-events: none;
      border: 1px solid black;
      background-color: transparent !important;
      color: black !important;
    }

    .fc-toolbar-chunk:nth-child(2) {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      & * {
        margin: 0;
      }

      & * + * {
        margin-left: 30px;
      }
    }
  }

  .fc-theme-standard .fc-scrollgrid {
    border: none !important;
  }

  .fc-daygrid-day {
    margin: 5px 0;
    position: relative;
    background: transparent;
    width: 80%;
    max-width: 80%;
    height: 80%;
    max-height: 80%;
    border-radius: 10px;
    min-height: 65px;
    cursor: pointer;
    .fc-daygrid-day-top {
      padding: 10px;
    }

    &:not(.fc-day--selected) {
      transition: 0.25s all ease-in-out;
      &:hover {
        transition: 0.25s all ease-in-out;
        @include themify($themes) {
          background: themed("calendarHover");
        }
      }
      &:active {
        transition: 0.25s all ease-in-out;
        background: $color-gray;
      }
    }

    &.fc-day-today:not(.fc-day--selected):not(:hover) {
      background-color: transparent !important;
    }

    &.fc-day--selected {
      background-image: $orange-gradient;

      a {
        color: white;
      }
    }

    &.fc-day--with-event:not(.fc-day--selected) {
      &::after {
        content: "";
        width: 100%;
        height: 4px;
        background-color: #ff6c00;
        position: absolute;
        bottom: 0;
        border-radius: 4px;
      }
    }
  }
  @include themify($themes) {
    .fc-col-header {
      width: 100% !important;
      .fc-col-header-cell {
        text-align: left;
        justify-content: flex-start;
        border-bottom: 2px solid themed("calendarHover");

        &:first-child {
          padding-left: 10px;
        }

        & + .fc-col-header-cell {
          padding-left: 25px;
        }
      }

      .fc-col-header-cell-cushion {
        color: themed("calendarText");

        font-weight: normal;
      }
    }
  }

  .fc-col-header-cell,
  .fc-scrollgrid {
    border: none;
  }
}

.calendar-label {
  height: 12px;
  width: 12px;
  border-radius: 50%;
  display: inline-block;
  @include directify($directions) {
    #{directed('margin-right')}: 5px;
  }

  &.calendar-label--red {
    background-color: $color-red;
  }

  &.calendar-label--green {
    background-color: $color-accent;
  }

  &.calendar-label--blue {
    background-color: $color-blue;
  }
}

.calendar--day_container {
  height: 100%;
  border-radius: 10px;
  overflow: hidden;
  @include themify($themes) {
    background-color: themed("calendarBackground");
  }
  display: flex;
  flex-direction: column;

  .day--header {
    z-index: 2;
    border-radius: 10px;
    height: 20%;
    background-image: $orange-gradient;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 15px 20px;

    & * {
      color: white;
    }
    & > div {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    & span {
      font-size: 1.05em;
      letter-spacing: 4px;
    }

    & .dot {
      background-color: white;
      content: "";
      width: 0.4em;
      height: 0.4em;
      border-radius: 0.4em;
      margin: 0 12px;
    }

    & h4 {
      font-size: 1.4em;
      letter-spacing: 0.1em;
    }
  }

  .day--content {
    height: 100%;
    padding: 20px;
    display: flex;
    flex-direction: column;

    & > div:last-child {
      margin-bottom: 20px;
    }
  }

  .day__scroll {
    width: 100%;
    height: 80%;
    overflow: visible !important;
    transition: width 0.3s;
    .scroll-content {
      height: 100%;
    }

    .scrollbar-track-y {
      height: calc(100% + 20px);

      .scrollbar-thumb-y {
        height: 100%;
      }
    }
  }
}

.day_event-component {
  width: 100%;
  @include themify($themes) {
    border-bottom: 2px solid themed("calendarHover");
  }
  padding: 13px 7px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;

  &:hover {
    @include themify($themes) {
      box-shadow: themed("dayHoverShadow");
    }
  }

  & p {
    margin: 0;
  }

  & .time {
    font-weight: bold;
    font-size: 0.9em;
  }
  & .title {
    font-size: 1.5em;
    letter-spacing: 0.05em;
    padding-bottom: 5px;
  }

  .info-container__row {
    display: flex;
    flex-direction: row;
    align-items: center;

    & p:not(:first-child),
    & > div {
      margin-left: 15px;
    }

    & p {
      padding-top: 2px;
    }
  }

  & .orange {
    color: $color-accent;

    & p {
      font-weight: bold;
      color: $color-accent;
    }
  }

  & .icon-container {
    cursor: pointer;
    &.orange path {
      fill: $color-accent;
    }
  }

  .checkbox-container {
    width: 25px;
    min-width: 25px;
    height: 25px;
    border-radius: 25px;

    align-items: center;
    justify-content: center;

    .material-checkbox {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 25px;
    }

    & .material-checkbox__image {
      width: 25px;
      height: 25px;
      border-radius: 25px;
      justify-content: center;
      align-items: center;
      transition: all 0.3s ease-in-out;

      &::after {
        border-width: 3px;
        top: 0;
        bottom: 2px;
        margin-top: auto;
        margin-bottom: auto;
      }

      &::after,
      &::before {
        transition: all 0.3s ease-in;
      }
    }

    & input[type="checkbox"] {
      & + span {
        background-image: $orange-gradient;
        border: none;
      }

      &:not(:checked) {
        & + span {
          width: 18px;
          height: 18px;
          border-radius: 18px;
          border: 2px solid white;
          background: transparent;
          box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
        }
      }
    }
  }
}

.calendar__navigation-buttons-container {
  display: flex;
  flex-direction: row;
  padding-right: 15px;

  & button {
    font-size: 15px;
    text-transform: none;

    &:nth-child(1) {
      margin-right: 20px;
    }
  }
}

.tasks-list-container {
  flex-wrap: wrap;
  padding-top: calc(40px + 1.5em);
}

.calendar {
  height: max-content;
}
