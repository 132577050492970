html, body {
    background: #efefef;      
    height:100%;  
  }
  #center-text {          
    display: flex;
    flex: 1;
    flex-direction:column; 
    justify-content: center;
    align-items: center;  
    height:100%; 
    
  }
  #chat-circle {
    position: fixed;
    bottom: 50px;
    right: 50px;
    background: #ff6600;
    width: 70px;
    height: 70px;  
    border-radius: 50%;
    color: white;
    padding: 23px;
    cursor: pointer;
    box-shadow: 0px 3px 16px 0px rgba(0, 0, 0, 0.6), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  }
  
  .btn#my-btn {
       background: white;
      padding-top: 13px;
      padding-bottom: 12px;
      border-radius: 45px;
      padding-right: 40px;
      padding-left: 40px;
      color: #5865C3;
  }
  #chat-overlay {
      background: rgba(255,255,255,0.1);
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      display: none;
  }
  
  
  .chat-box {
    // display:none;
    background: #efefef;
    position:fixed;
    right:30px;
    bottom:50px;  
    width:350px;
    max-width: 85vw;
    max-height:100vh;
    border-radius:5px;  
    box-shadow: 0px 5px 35px 9px #ccc;
    z-index: 3;
  }
  .chat-box-toggle {
    float:right;
    margin-right:15px;
    cursor:pointer;
  }
  .chat-box-header {
    background-image: linear-gradient(0.15turn, #f33a01, #ff9201 80%);
    height:70px;
    border-top-left-radius:10px;
    border-top-right-radius:10px; 
    color:white;
    text-align:center;
    font-size:20px;
    padding-top:17px;
  }
  .chat-box-body {
    position: relative;  
    height:370px;  
    height:auto;
    border:1px solid #ccc;  
    overflow: hidden;
  }
  
  #chat-input {
    background: #f4f7f9;
    width:100%; 
    position:relative;
    height:47px;  
    padding-top:10px;
    padding-right:50px;
    padding-bottom:10px;
    padding-left:15px;
    border:none;
    resize:none;
    outline:none;
    border:1px solid #ccc;
    color:#888;
    border-top:none;
    border-bottom-right-radius:5px;
    border-bottom-left-radius:5px;
    overflow:hidden;  
  }
  .chat-input > form {
      margin-bottom: 0;
  }
  #chat-input::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: #ccc;
  }
  #chat-input::-moz-placeholder { /* Firefox 19+ */
    color: #ccc;
  }
  #chat-input:-ms-input-placeholder { /* IE 10+ */
    color: #ccc;
  }
  #chat-input:-moz-placeholder { /* Firefox 18- */
    color: #ccc;
  }
  .chat-submit {  
    position:absolute;
    bottom:3px;
    right:10px;
    background: transparent;
    box-shadow:none;
    border:none;
    border-radius:50%;
    color: grey;
    width:35px;
    height:35px;  
  }
  .chat-logs {
    padding:15px; 
    height:370px;
    overflow-y:scroll;
  }
  
  // .chat-logs::-webkit-scrollbar-track
  // {
  //   //   -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  //     background-color: #F5F5F5;
  // }
  
  // .chat-logs::-webkit-scrollbar
  // {
  //     width: 5px;  
  //     background-color: #F5F5F5;
  // }
  
  // .chat-logs::-webkit-scrollbar-thumb
  // {
  //     background-color: #5A5EB9;
  // }
  
  
  
  @media only screen and (max-width: 500px) {
     .chat-logs {
          height:40vh;
      }
  }
  
  .chat-msg.user > .msg-avatar img {
    width:45px;
    height:45px;
    border-radius:50%;
    float:left;
    width:15%;
  }
  .chat-msg.self > .msg-avatar img {
    width:45px;
    height:45px;
    border-radius:50%;
    float:right;
    width:15%;
  }
  .cm-msg-text {
    background:white;
    padding:10px 15px 10px 15px;  
    color:#666;
    max-width:75%;
    float:left;
    margin-left:10px; 
    position:relative;
    margin-bottom:20px;
    border-radius:30px;
  }
  .chat-block {
    display: flex;
    flex-direction: column;
  }
  .cm-msg-date {
    display: flex;
    justify-content: center;
  }
  .chat-msg {
    clear:both;    
  }
  .chat-msg.self > .cm-msg-text {  
    float:right;
    margin-right:10px;
    background: #ff6600;
    color:white;
  }
  .cm-msg-button>ul>li {
    list-style:none;
    float:left;
    width:50%;
  }
  .cm-msg-button {
      clear: both;
      margin-bottom: 70px;
  }