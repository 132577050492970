



@media (max-width: 1050px) {
    .task__container{
        .task-container__main-card-body{
            padding:0px !important;
        }

     
    }
}
@media (max-width: 991px) {
    .task__container{
  

        .task__left-indicators{
            display: none !important;
        }
    }
}

@media (max-width: 766px){
    .task__sm-centered{
        text-align: center;
        display: flex;
        justify-content: center !important;
        margin:1rem 0;

        
    }

    .task__sm_d-block{
        display: block !important;
        div{
            margin: 1rem 0;
        }
    }
}


.doc-viewer-modal{
    width: 90vw;
    max-width: 90vw;
}